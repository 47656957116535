import React from "react"
import Layout from "../components/layout/Layout"
import { Seo as SEO } from "../components/Seo"
import { graphql } from "gatsby"
import MessageSentContent from "../components/MessageSentContent"

export default function MessageSentPage({data}){
    return(
        <Layout>
            <MessageSentContent/>
        </Layout>
    )
}

export const Head = () => {
    return (
      <SEO
        title={'Thank You'}
        noIndex
      />
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["contact"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`