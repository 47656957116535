import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useUserAction } from "../utils/context/actions"
import { navigate } from "gatsby"

export default function MessageSentContent(){
    const { t } = useTranslation()
    const { hasTakenAction, setHasTakenAction } = useUserAction()
    const [countdown, setCountdown] = useState(10)

    useEffect(() => {
        if(!hasTakenAction){
            navigate('/contact')
        }
        return () => setHasTakenAction(false)
    }, [hasTakenAction, setHasTakenAction])

    useEffect(() => {
        if(countdown < 0) setHasTakenAction(false)
        else setTimeout(() => setCountdown(countdown - 1), 1000)
    }, [countdown])

    if(!hasTakenAction) return null

    return(
        <div className="w-full h-[560px] flex flex-col justify-center items-center gap-2">
            <div className="text-4xl md:text-6xl font-bold text-dark-green">{t("Thank You")}</div>
            <div className="text-base md:text-lg text-dark/80">{t("Thank You Message")}</div>
            <div className="mt-2 text-sm">{t("Redirect")} {countdown}</div>
        </div>
    )
}